import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import { styled } from "@mui/system";
import {
  Container,
  Box,
  Button,
  Grid,
  Typography,
  Stack,
  Card,
  CardHeader,
  Divider,
  CardContent,
} from "@mui/material";
import TabsUnstyled from "@mui/base/TabsUnstyled";
import TabsListUnstyled from "@mui/base/TabsListUnstyled";
import TabPanelUnstyled from "@mui/base/TabPanelUnstyled";
import { buttonUnstyledClasses } from "@mui/base/ButtonUnstyled";
import TabUnstyled, { tabUnstyledClasses } from "@mui/base/TabUnstyled";
import ContactModal from "../components/modals/Contact";
import Seo from "../components/seo";
import AboveImage from "../images/aboveimg.png";
import ServicesImage from "../images/fold.png";
import IconOne from "../images/one.inline.svg";
import IconTwo from "../images/two.inline.svg";
import IconFive from "../images/five.inline.svg";
import Corner from "../images/corner.inline.svg";
import CornerSecond from "../images/corner_second.inline.svg";
import Map from "../components/animations/map.js";
import MapImage from "../images/map.inline.svg";
import Header from "../components/Header";

const Tab = styled(TabUnstyled)`
  color: white;
  cursor: pointer;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 24px;
  text-transform: uppercase;
  color: #f4f7fa;
  background-color: transparent;
  width: 100%;
  padding: 20px 10px;
  border: none;
  border-radius: 5px;
  display: flex;
  justify-content: center;

  &.${tabUnstyledClasses.selected} {
    color: white;
    font-weight: bold;
    position: relative;
  }

  &.${tabUnstyledClasses.selected}:after {
    content: " ";
    height: 3px;
    width: calc(100% - 55px);
    background: #f2bc46;
    position: absolute;
    bottom: 12px;
    margin: auto;
  }

  &.${buttonUnstyledClasses.disabled} {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const TabPanel = styled(TabPanelUnstyled)`
  width: 100%;
  font-family: IBM Plex Sans, sans-serif;
  font-size: 0.875rem;
`;

const TabsList = styled(TabsListUnstyled)`
  max-width: 830px;
  background-color: #14274e;
  margin: 0 auto;
  border-radius: 8px;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: space-between;
  margin-bottom: 40px;
  padding-left: 10px;
  padding-right: 10px;
`;

export default function indexPage({ data }) {
  const sevices = [
    {
      text: "Investment in Technological Enterprises & Management"
    },
    {
      text: "Investment in Healthcare Enterprises & Development"
    },
    {
      text: "Investment in Educational Enterprises & Management"
    },
    {
      text: "Investment in Energy Enterprises & Management"
    }
  ];
  return (
    <Layout>
      <Seo
        title="Afrasam"
        description="AFRASAM is a global investment management firm, built on a sophisticated operating system at scale. We seek to pursue a diverse array of investment strategies, and we empower our employees to deliver exceptional outcomes and enable our portfolio managers to do what they do best – navigate the markets."
        image="/cover.png"
      />
      <Header />
      <Box
        id="scroll-up"
        className="home-above"
        sx={{
          py: { xs: 12, md: 12 },
          pt: { xs: 20, md: 20 },
          marginBottom: "-1px",
          background: "#F4F7FA",
          backgroundImage: `url(${AboveImage})`,
          backgroundPosition: "center center",
          backgroundSize: "cover",
          position: "relative",
        }}
      >
        <Container maxWidth="lg" style={{ position: "relative", zIndex: "2" }}>
          <Grid container spacing={4}>
            <Grid item xs={12} md={7}>
              <Stack direction="column" spacing={3}>
                <Typography
                  variant="h1"
                  sx={{ color: "secondary.contrastText" }}
                >
                  Who are we
                </Typography>
                <Typography
                  variant="body1"
                  sx={{ color: "secondary.contrastText" }}
                >
                  AFRASAM is a global investment management firm, built on a sophisticated operating system at scale. We seek to pursue a diverse array of investment strategies, and we empower our employees to deliver exceptional outcomes and enable our portfolio managers to do what they do best – navigate the markets.
                </Typography>
                <Stack
                  spacing={2}
                  direction="row"
                  sx={{ paddingBottom: { xs: "80px", md: "180px" } }}
                >
                  <ContactModal
                    ButtonName="GET IN TOUCH"
                    CustomClass="nopadding"
                  />
                  {/* <VideoModal videoId="ifwc5xgI3QM" /> */}
                </Stack>
              </Stack>
            </Grid>
          </Grid>
        </Container>
        <Corner
          style={{
            position: "absolute",
            bottom: "-2px",
            left: "0px",
            width: "100%",
            height: "auto",
            transform: "scaleY(-1)",
          }}
        />
      </Box>
      <Box
        sx={{
          py: { xs: 5, md: 10 },
          pb: { xs: 0, md: 10 },
          background: "#F4F7FA",
        }}
      >
        <Container maxWidth="lg">
          <Grid container spacing={4}>
            <Grid item xs={12} md={12}>
              <Stack direction="column" spacing={3}>
                <Typography variant="h2" sx={{ textAlign: "center" }}>
                  Why Afrasam{" "}
                  <Divider
                    sx={{
                      marginTop: { xs: "20px", md: "40px" },
                      marginBottom: { xs: "30px", md: "50px" },
                      width: "160px",
                      backgroundColor: "#F2BC46",
                      height: "3px",
                      margin: "0 auto",
                    }}
                  />
                </Typography>
                <Grid item md={12} xs={12} alignItems="stretch">
                  <Stack
                    direction={{ xs: "column", md: "row" }}
                    spacing={4}
                    style={{ justifyContent: "space-between" }}
                  >
                    <Card
                      key='our-model'
                      sx={{
                        flexGrow: { xs: "0", md: "1" },
                        flexBasis: { xs: "1", md: "0" },
                        backgroundColor: "transparent",
                        boxShadow: "none",
                      }}
                    >
                      <CardHeader
                        style={{
                          flexDirection: "column",
                          alignItems: "flex-start",
                        }}
                        avatar={<IconOne />}
                      />
                      <CardContent>
                        <Typography
                          variant="h3"
                          style={{ marginBottom: "15px" }}
                        >
                          Our model
                        </Typography>
                        <Typography variant="body1">
                        AFRASAM’s model prioritizes portfolio manager autonomy within our framework, 
                        and our entrepreneurial environment enables them to manage their investment process and drive their own outcomes. 
                        We seek to accelerate their success by providing sophisticated resources and technology. 
                        All of this occurs within a robust risk management framework that is transparent to our portfolio managers 
                        and tailored to individual teams and their strategies.
                        </Typography>
                      </CardContent>
                    </Card>
                    <Card
                      key='constant-evolution'
                      sx={{
                        flexGrow: { xs: "0", md: "1" },
                        flexBasis: { xs: "1", md: "0" },
                        backgroundColor: "transparent",
                        boxShadow: "none",
                      }}
                    >
                      <CardHeader
                        style={{
                          flexDirection: "column",
                          alignItems: "flex-start",
                        }}
                        avatar={<IconTwo />}
                      />
                      <CardContent>
                        <Typography
                          variant="h3"
                          style={{ marginBottom: "15px" }}
                        >
                          Constant evolution
                        </Typography>
                        <Typography variant="body1">
                        Consistency doesn’t come easily. Achieving our mission to deliver high-quality returns requires unyielding focus, 
                        continually adapting to dynamic conditions and actively pursuing market opportunities. 
                        In seeking to achieve this, we invest significantly in technology and infrastructure and bring together diverse perspectives and approaches
                        </Typography>
                      </CardContent>
                    </Card>
                    <Card
                      key='extraordinary-reach'
                      sx={{
                        flexGrow: { xs: "0", md: "1" },
                        flexBasis: { xs: "1", md: "0" },
                        backgroundColor: "transparent",
                        boxShadow: "none",
                      }}
                    >
                      <CardHeader
                        style={{
                          flexDirection: "column",
                          alignItems: "flex-start",
                        }}
                        avatar={<IconFive />}
                      />
                      <CardContent>
                        <Typography
                          variant="h3"
                          style={{ marginBottom: "15px" }}
                        >
                          Extraordinary reach
                        </Typography>
                        <Typography variant="body1">
                        AFRASAM designs and builds some of the most sophisticated tools and functions in the industry. 
                        By operating at scale, we believe our people are well-positioned to solve complex challenges on a global level.
                        </Typography>
                      </CardContent>
                    </Card>
                  </Stack>
                </Grid>
              </Stack>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Box
        sx={{
          py: { xs: 10, md: 12 },
          pt: { xs: 30, md: 40 },
          pb: { xs: 25, md: 40 },
          background: "#F4F7FA",
          backgroundImage: `url(${ServicesImage})`,
          backgroundPosition: "center center",
          backgroundSize: "cover",
          position: "relative",
        }}
      >
        <Corner
          style={{
            position: "absolute",
            top: "0",
            left: "0",
            width: "100%",
            height: "auto",
          }}
        />
        <CornerSecond
          style={{
            position: "absolute",
            bottom: "0",
            left: "0",
            width: "100%",
            height: "auto",
          }}
        />
        <Container maxWidth="lg" style={{ position: "relative" }}>
          <Typography
            variant="h2"
            sx={{ textAlign: "center", color: "#F2BC46" }}
          >
            Our services{" "}
            <Divider
              sx={{
                marginTop: { xs: "20px", md: "40px" },
                marginBottom: { xs: "30px", md: "50px" },
                width: "160px",
                backgroundColor: "#F4F7FA",
                height: "3px",
                margin: "0 auto",
              }}
            />
          </Typography>
          <Grid container spacing={0}>
            <TabsUnstyled defaultValue={0}>
              <TabPanel value={0}>
                <Stack
                  direction={{ xs: "column", md: "row" }}
                  sx={{ flexWrap: "wrap" }}
                >
                  {sevices.map((node, _index) => (
                    <Card
                      key={`service-${_index}`}
                      sx={{
                        maxWidth: { xs: "100%", md: "calc(33% - 15px)" },
                        flex: "1 1 30%",
                        margin: "10px",
                        background: "#F4F7FA",
                      }}
                    >
                      <CardContent>
                        
                        <Typography
                          variant="body1"
                          style={{ color: "#14274E" }}
                        >
                          {node.text}
                        </Typography>
                      </CardContent>
                    </Card>
                  ))}
                </Stack>
              </TabPanel>
            </TabsUnstyled>
          </Grid>
        </Container>
      </Box>
      
      <Box
        sx={{
          py: 12,
          pt: { xs: 15, md: 40 },
          pb: { xs: 5, md: 15 },
          background: "#14274E",
          position: "relative",
        }}
      >
        <Corner
          style={{
            position: "absolute",
            top: "0",
            left: "0",
            width: "100%",
            height: "auto",
          }}
        />
        <Container maxWidth="lg" style={{ position: "relative" }}>
          <Typography
            variant="h2"
            sx={{ textAlign: "center", color: "#F2BC46" }}
          >
            Our global reach{" "}
            <Divider
              sx={{
                marginTop: { xs: "20px", md: "40px" },
                marginBottom: { xs: "30px", md: "50px" },
                width: "160px",
                backgroundColor: "#F4F7FA",
                height: "3px",
                margin: "0 auto",
              }}
            />
          </Typography>
          <Grid item md={12} xs={12}>
            <Stack style={{ position: "relative" }}>
              <MapImage
                style={{
                  position: "absolute",
                  width: "100%",
                  top: "0",
                  marginBottom: '60px',
                  height: "auto",
                }}
              />
              <Map />
            </Stack>
          </Grid>
        </Container>
      </Box>
      

      <Box
        sx={{
          py: { xs: 10, md: 12 },
          pt: { xs: 2, md: 3 },
          pb: { xs: 10, md: 12 },
          pr: 2,
          pl: 2,
          background: "#F4F7FA",
          position: "relative",
        }}
      >
        <Container
          maxWidth="md"
          style={{
            position: "relative",
            background: "#14274E",
            borderRadius: "14px",
            paddingLeft: "40px",
            paddingRight: "40px",
          }}
        >
          <Grid
            item
            md={8}
            xs={12}
            sx={{
              pt: { xs: 4, md: 6 },
              pb: { xs: 4, md: 6 },
              margin: "auto",
            }}
          >
            <Stack
              direction={{ xs: "column", md: "column" }}
              spacing={2}
              xs={{ justifyContent: "space-between" }}
            >
              <Typography
                variant="h2"
                sx={{ textAlign: "center", color: "white" }}
              >
                Reach out to us
              </Typography>
              <Typography
                variant="body1"
                sx={{ textAlign: "center", color: "white" }}
              >
                If you feel your business or organisation could benefit from the
                services we provide, we’d love to hear from
                you!
              </Typography>
              <ContactModal
                    ButtonName="GET IN TOUCH"
                    CustomClass="nopadding"
                  />
            </Stack>
          </Grid>
        </Container>
      </Box>
    </Layout>
  );
}

export const query = graphql`
  query {
    vc: file(relativePath: { eq: "aboveimg.png" }) {
      childImageSharp {
        fluid(maxWidth: 2000, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
